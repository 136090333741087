<template>
  <div class="bottom">
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="container" flex column align>
          <div class="b_top" flex between align>
            <div class="left">
              <p>全国咨询热线</p>
              <p>028-6391 8965</p>
              <div class="item_p" flex column between>
                <p>邮箱：sdblo@sdblo.com</p>
                <p>
                  地址：<span @click="checkAddress"
                    >成都高新南区天府大道北段1700号环球中心</span
                  >
                </p>
              </div>
            </div>
            <div class="right" flex>
              <div class="right_item">
                <div flex align center>
                  <img src="../assets/img/lygj-wx.png" alt="" width="112px" height="112px" />
                </div>
                <p>邻云管家小程序</p>
              </div>
              <div class="right_item">
                <div flex align center>
                  <img src="../assets/img/lygj-app.png" alt="" width="112px" height="112px" />
                </div>
                <p>邻云管家公众号</p>
              </div>
            </div>
          </div>
          <div class="b_botm item_p" flex column between>
            
            <p>
              Copyright © 2020 成都赛德部落科技有限公司 All rights reserved
              <span @click="toGoverment">蜀ICP备16006428号</span>
                <!-- <span @click="toGoverment">蜀ICP备2021005995号-1</span> -->
             <!-- <span @click="toGoverment">蜀ICP备2021005995号-2</span> -->
            </p>
           
            <p>
              公司地址：<span @click="checkAddress"
                >成都高新南区天府大道北段1700号环球中心</span
              >
            </p>
          </div>
        </div>
        <p class="border"></p>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  methods: {
    //打开政务服务平台
    toGoverment() {
      window.open("https://beian.miit.gov.cn/index", "blank"); //主网站
      // window.open("https://beian.miit.gov.cn/", "blank"); //备案网站
    },
    //打开百度地图查看公司地址
    checkAddress() {
      window.open(
        "https://map.baidu.com/search/%E6%88%90%E9%83%BD%E9%AB%98%E6%96%B0%E5%8D%97%E5%8C%BA%E5%A4%A9%E5%BA%9C%E5%A4%A7%E9%81%93%E5%8C%97%E6%AE%B51700%E5%8F%B7%E7%8E%AF%E7%90%83%E4%B8%AD%E5%BF%83/@11584942.818870967,3555747.75,18.49z?querytype=con&wd=%E6%88%90%E9%83%BD%E9%AB%98%E6%96%B0%E5%8D%97%E5%8C%BA%E5%A4%A9%E5%BA%9C%E5%A4%A7%E9%81%93%E5%8C%97%E6%AE%B51700%E5%8F%B7%E7%8E%AF%E7%90%83%E4%B8%AD%E5%BF%83&c=75&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
        "blank"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.bottom {
  width: 100%;
  height: 400px;
  min-width: 1200px;
  background: rgba(55, 56, 64, 1);
  color: rgba(176, 176, 176, 1);
  .container {
    width: 100%;
    height: 400px;
  }
  .b_top {
    height: 300px;
    text-align: left;
    width: 100%;
    .left {
      > p:nth-of-type(1) {
        color: #fff;
        font-size: 16px;
      }
      > p:nth-of-type(2) {
        padding: 15px 0;
        font-size: 24px;
        color: #fff;
      }
    }
    .right {
      > div:nth-of-type(1) {
        padding-right: 60px;
      }
      > div {
        text-align: center;
        > p {
          padding: 8px 0;
        }
        > div {
          width: 130px;
          height: 130px;
          border: 1px solid #757f94;
        }
      }
    }
  }
  .item_p {
    font-size: 14px;
    height: 50px;
    > p {
      > span {
        cursor: pointer;
        padding-left: 5px;
      }
      > span:hover {
        border-bottom: 1px solid rgba(176, 176, 176, 1);
      }
    }
  }
  .b_botm {
    margin-top: 25px;
    text-align: center;
  }
}
.border {
  width: 100%;
  height: 2px;
  background-color: #414855;
  position: relative;
  top: -100px;
}
</style>