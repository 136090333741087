<template>
  <div class="bar">
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <!-- <el-col :span="9"></el-col> -->
      <el-col :span="16">
        <div class="container" flex between align>
          <div class="left" @click="toHome">成都赛德部落科技有限公司</div>
          <div class="right">
            <div
              v-for="item in menu_list"
              :key="item.id"
              @click="checkMenu(item)"
              :class="item.id == menu_id ? 'checked' : 'unchecked'"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu_list: [
        {
          id: 1,
          name: "首页",
          path: "/home",
        },
        {
          id: 2,
          name: "解决方案",
          path: "/solution",
        },
        // {
        //   id: 3,
        //   name: "智能硬件",
        //   path: "/hardware",
        // },
        {
          id: 4,
          name: "定制服务",
          path: "/service",
        },
        {
          id: 5,
          name: "帮助中心",
          path: "/help",
        },
        // {
        //   id: 6,
        //   name: "下载",
        //   path: "/download",
        // },
      ],
      menu_id: sessionStorage.getItem("menu_id"),
    };
  },
  created(){
    
  },

  methods: {
    menu_session(){
      let menu_id=sessionStorage.getItem("menu_id")
      if(menu_id){
        this.menu_id=Number(menu_id)
      }else{
        this.menu_id=1
        sessionStorage.setItem("menu_id", 1);
      }
    },


    checkMenu(item) {
      if (item.id != this.menu_id) {
        this.menu_id = item.id;
        sessionStorage.setItem("menu_id", item.id);
        if (item.path) {
          this.$router.push(item.path);
        }
      }
    },
    toHome() {
      if (this.$route.path != "/home") {
        sessionStorage.setItem("menu_id", 1);
        this.$router.push("/home");
      }
    },
  },
  watch: {
    menu_id() {
      this.menu_id = sessionStorage.getItem("menu_id");
    },
    "$route.path"() {
      this.menu_list.forEach((item) => {
        if (item.path == this.$route.path) {
          this.menu_id = item.id;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bar {
  width: 100vw;
  height: 80px;
  min-width: 1200px;
  // background: linear-gradient(180deg, rgba(40, 40, 40, 0.38) 62%);
  background-color: rgba(40, 40, 40, 0.38);
  z-index: 200;
  position: fixed;
  box-shadow: 0px 4px 8px 0px rgba(15, 15, 15, 0.08);
  top: 0;
  left: 0;
  .left {
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #f2f2f2;
    cursor: pointer;
  }
  .right {
    color: #fff;
    display: flex;
    > div {
      text-align: center;
      margin: 0 20px 0 0;
      cursor: pointer;
    }
    >div:hover {
      background-color: #f78900;
    }
    .checked {
      background: #f78900;
    }
  }
}
@media screen and (min-width: 1200.1px) {
  .bar {
    height: 80px;
    line-height: 80px;
    .left {
      font-size: 28px;
    }
    .right {
      font-size: 16px;
      > div {
        width: 80px;
        height: 80px;
        margin: 0 10px 0 0;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .bar {
    height: 60px;
    line-height: 60px;
    .container {
      height: 60px;
    }
    .left {
      font-size: 22px;
    }
    .right {
      font-size: 14px;
      > div {
        width: 70px;
        height: 60px;
        text-align: center;
        margin: 0 10px 0 0;
      }
    }
  }
}
</style>